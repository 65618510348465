<template>
    <div>
        <div class="sidebar">
            <router-link to="/m/app"> <div><i class='bx bx-leaf'></i></div> <span>All Leads</span></router-link>
            <router-link to="/m/clients"> <div><i class='bx bx-group'></i></div> <span>Customers</span></router-link>
            <a href="#" @click.prevent="paymodal = true"><div><i class='bx bx-money'></i></div> <span>Request payout</span></a>
            <a href="#" @click.prevent="signOut"><div><i class='bx bx-power-off'></i></div> <span>Sign out</span></a>
        </div>

        <b-modal v-model="paymodal" :width="340">
            <div class="moda-card">
                <div class="modal-card-head">
                    <h3 class="modal-card-title">Request payout</h3>
                </div>
                <section class="modal-card-body">
                   <p>Please note minimum withdrawal amount is £100. We will process your request as quickly as possible.</p>
                </section>
                <footer class="modal-card-foot">
                    <b-button type="is-light" @click="paymodal = false">Cancel</b-button>
                    <b-button type="is-info" @click="payout">Request payout</b-button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>




<script>
import { notification } from '../utils'
import { users } from '@/stores/index'
import firebase from 'firebase'


export default {
    name: 'PartnerNav',
    data: () => ({
        paymodal: false, store: users()
    }),
    methods: {
        payout(){
            if(parseInt(this.store.user.earned) < 100){
              return this.toasted('is-warning', 'Limited balance! Minimum withdrawal is £100')
            }
            notification(this.store.user, {
                kind: 'payout',
                msg: `Partner with w/ ID: ${this.store.user.partner} requested a payout. Completed details can be found on the partner tab`
            })
            this.paymodal = false
            this.toasted('is-success', 'Payout request sent successfully!')
        },
        signOut(){
            firebase.auth().signOut().then(() => {
                this.$router.push('/login')
            })
        },
        toasted(type, msg){
           this.$buefy.toast.open({
               message: msg, type: type
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import url("https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css");
@import '../assets/scss/style';


</style>