<template>
    <div class="panel--main">
        <PartnerNav />

        <b-modal v-model="dialogLead" :width="500">
            <div class="moda-card">
                <form @submit.prevent="addLead">
                <div class="modal-card-head">
                    <h2 class="modal-card-title">Lead</h2>
                </div>
                <section class="modal-card-body">
                     <div class="control flex mt-3">
                         <b-field  class="w-1-2" label="First name*" :label-position="'on-border'">
                             <b-input v-model="form.fname"  required></b-input>
                         </b-field>
                          <b-field class="w-1-2" label="Last name*" :label-position="'on-border'">
                             <b-input v-model="form.lname" required></b-input>
                         </b-field>
                    </div>
                    <div class="control flex">
                          <b-field  class="w-1-2" label="Phone number*" :label-position="'on-border'">
                             <b-input v-model="form.phone" required></b-input>
                         </b-field>
                          <b-field class="w-1-2" label="Email address" :label-position="'on-border'">
                             <b-input type="email" v-model="form.email"></b-input>
                         </b-field>
                    </div>
                      <div class="control flex">
                        <b-field  class="w-1-1" label="Premises address" :label-position="'on-border'">
                            <b-input v-model="form.address"></b-input>
                        </b-field>
                    </div>
                     <div class="control flex">
                          <b-field  class="w-1-2" label="Business name" :label-position="'on-border'">
                             <b-input v-model="form.bname"></b-input>
                         </b-field>
                          <b-field class="w-1-2" label="City/state" :label-position="'on-border'">
                             <b-input v-model="form.city"></b-input>
                         </b-field>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-button native-type="submit" type="is-primary" expanded>Save lead & close</b-button>
                </footer>
              </form>
            </div>
        </b-modal>

        
        <b-modal v-model="delmodal" :width="340">
            <div class="moda-card">
                <div class="modal-card-head">
                    <h3 class="modal-card-title">Delete {{form.fname}} from lead</h3>
                </div>
                <section class="modal-card-body">
                  <p>Are you sure you want to delete this lead? This action cannot be undone.</p>
                </section>
                <footer class="modal-card-foot">
                    <b-button type="is-light" @click="delmodal = false">Cancel</b-button>
                    <b-button type="is-danger" @click="confirmDel">Delete pamarnetely</b-button>
                </footer>
            </div>
        </b-modal>

        <b-modal v-model="paymodal" :width="340">
            <div class="moda-card">
                <div class="modal-card-head">
                    <h3 class="modal-card-title">Request payout</h3>
                </div>
                <section class="modal-card-body">
                   <p>Please note minimum withdrawal amount is £100. We will process your request as quickly as possible.</p>
                </section>
                <footer class="modal-card-foot">
                    <b-button type="is-light" @click="paymodal = false">Cancel</b-button>
                    <b-button type="is-info" @click="payout">Request payout</b-button>
                </footer>
            </div>
        </b-modal>



        <div class="lead-section panel-x">
            <h2 class="panel-x__title center">Leads, stats and earnings</h2> 

                <div class="partner-stats center">
                    <h2><a href="#" @click.prevent="paymodal = true">Your earnings<i class="bx bx-chevron-right"></i></a></h2>
                     <div class="flex earning-tab">
                        <div>
                            <h1><small>£</small>{{sumOfLead}}</h1>
                            <p>Total</p>
                        </div>
                           <div>
                            <h1><small>£</small>{{store.user.earned}}</h1>
                            <p>Balance</p>
                        </div>

                     </div>
                    <p>Minimum withdrawal is £100</p>
                </div>

               <div class="control">
                <b-taglist attached>
                    <b-tag type="is-success">{{leads.filter(i => i.status == 'client').length}} customers</b-tag>
                    <b-tag type="is-light">{{leads.length}} leads</b-tag>
                </b-taglist>
             </div>
            <div class="lead-section__filters grid">
                <b-input type="search" @input="searchLead" size="is-medium" icon="magnify"  placeholder="Search for..."></b-input>
                <b-button type="is-primary is-light" class="ml-4" size="is-medium" @click="popLeadModal">Add new lead</b-button>
            </div>
            <br><br>

            <div class="lead-section__table">
                <b-collapse class="ui-collapse"
                        animation="slide" v-for="(item, index) of leads" :data-sefn="item.fname" :data-seln="item.lname"
                        :key="index" :open="isOpen == index" @open="isOpen = index" :aria-id="'contentIdForA11y5-' + index">
                        <template #trigger="props">
                            <div role="button" :aria-controls="'contentIdForA11y5-' + index" :aria-expanded="props.open">
                             <div class="lead-list-main">
                                 <div class="lead-date center">
                                     <h1 class="uppercase">{{datefm(item.date)}}</h1>
                                 </div>
                                 <div class="lead-fullname">
                                     <h4>{{item.fname}}</h4> <h5>{{item.lname}}</h5>
                                 </div>
                                 <div class="lead-phone-email">
                                     <h4>{{item.phone}}</h4>
                                     <h5>{{item.email}}</h5>
                                 </div>
                                 <div class="lead-status center">
                                     <div class="icon-lead" :class="statusColor(item.status)">
                                         <i class='bx' :class="leadIcon(item.status)"></i>
                                     </div>
                                     <h5 class="uppercase">{{item.status}}</h5>
                                 </div>
                                 <div class="leaf-ref__amount">
                                     <b-tooltip type="is-light" label="Earning">
                                         <h3>£{{item.ref_amount}}</h3>
                                     </b-tooltip>
                                 </div>
                                 <a class="card-header-icon">
                                    <b-icon size="is-large" :icon="props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
                                </a>
                             </div>
                            </div>
                        </template>
                        <div class="card-contet">
                            
                            <div class="buttons mt-5">
                                <div class="w-1-2 flex">
                                    <b-button type="is-light" expanded size="is-small" @click="editLead(item)">Edit lead information</b-button>
                                    <b-button type="is-danger is-light" class="ml-3" size="is-small" @click="delLead(item)">Delete</b-button>
                                </div>
                            </div>
                        </div>
                </b-collapse>
            </div>
            <empty v-show="leads.length == 0"><h1>No leads</h1></empty>


        </div>
    </div>
</template>


<script>

import PartnerNav from '@/components/PartnerNav.vue'
import Empty from '@/components/Empty.vue'
import firebase from 'firebase'
import moment from 'moment'
import { db, notification, customSearch, costCollection } from '../../utils'
import { users } from '@/stores/index'



export default {
    name: 'Partner',
    components: {
        PartnerNav, Empty
    },
    data: () => ({
        user: {}, form: {}, store: {user: {}}, leads: [],
        currentPage: 1, isOpen: 0, dialogLead: false, sumOfLead: null,
        mode: 0, delmodal: false, paymodal: false, leadCopy: [], 
      
    }),
    mounted(){
        this.store = users()
        firebase.auth().onAuthStateChanged((user) => {
        if (!user) return this.$router.push('/login')
            this.user = user
            this.store.logged = this.user
            this.init()
        })
    },
    methods:{
        statusColor(status){
            return status == 'lead added' ? 'is-info' : status == 'working lead' ? 'is-link' 
            : status == 'dead lead' ? 'is-danger' : 'is-success'
        },
        leadIcon(status){
            return status == 'lead added' ? 'bxs-circle-half' : status == 'working lead' ? 'bxs-circle-three-quarter' 
            : status == 'dead lead' ? 'bxs-circle' : 'bxs-party'
        },
        init(){
            db.collection("leads").where("partnerId", "==", this.user.uid).
              onSnapshot((doc) => {
                this.leads = []
                 doc.forEach((items) => {
                   let item = {...items.data(), ... {oid: items.id}}
                   this.leads.push(item)
                })
                this.store.leads = this.leads
                this.leadCopy = this.leads
                const cost = new costCollection(... this.leads)
                this.sumOfLead = cost.sum('ref_amount').toFixed(2)

            });


            db.collection("partners").where("partner", "==", this.user.uid).onSnapshot(user => {
                let item  = user.docs.map(i => i.data())
                this.store.user = item[0]
            })
        },
        datefm(d){
            return moment(d).format('D MMM')
        },
        addLead(){
            let extra = {
                partnerId: this.user.uid,
                date: moment().format(),
                ref_amount: '0.00',
                status: 'lead added'
            }

            if(this.mode == 1){
                db.collection('leads').doc(this.form.oid).update(this.form)
                return this.showToast('updated')
            }

           this.form = {...this.form, ...extra}

           db.collection('leads').add(this.form).then(item => {
               if(item) this.showToast('created')
           }).catch(err => {
               console.log(err, 'ERROR');
           })
        },
        editLead(items){
            this.form = items
            this.dialogLead = true
            this.mode = 1
        },
        delLead(item){
            this.form  = item
            this.delmodal = true
        },
        confirmDel(){
            db.collection('leads').doc(this.form.oid).delete()
            this.delmodal = false
        },
        popLeadModal(){
            this.form = {}
            this.dialogLead = true
            this.mode = 0
        },
         payout(){
            if(parseInt(this.store.user.earned) < 100){
              return this.toasted('is-warning', 'Limited balance! Minimum withdrawal is £100')
            }
            notification(this.store.user, {
                kind: 'payout',
                msg: `Partner with w/ ID: ${this.store.user.partner} requested a payout. Completed details can be found on the partner tab`
            })
            this.paymodal = false
            this.toasted('is-success', 'Payout request sent successfully!')
        },
        toasted(type, msg){
           this.$buefy.toast.open({
               message: msg, type: type
            })
        },
        searchLead(e){
            let leadsO = this.leadCopy;
            this.leads = customSearch(leadsO, e)

            if(!e || e == '') {
                this.leads = this.leadCopy
            }
        },
        showToast(item){
            this.dialogLead = false
             this.toasted('is-success', 'Changes saved successfully!')
             notification(this.store.user, {
                 kind: item, 
                 msg: `Partner recently ${item} a lead. Completed details can be found on the lead tab`
             })
        }
    },

}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/style.scss';
@import '../../assets/scss/lead.scss';

.lead-section__table{
   padding-bottom: 20px;
   border-radius: 20px 20px  0 0;
}

.panel--main{
    background: #fff;
}

.earning-tab{
    width: 250px;
    margin: 30px auto;
}


</style>